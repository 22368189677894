import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import claimFileService from "../../../services/claimFileService";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  Button,
  Breadcrumbs,
  Tabs,
  Tab,
  Modal,
  TextField,
} from "@mui/material";
import { instanceToken } from "../../../utils/constant";
import { useForm } from "react-hook-form";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ClaimFile = ({ homeAlert }) => {
  const { insurance_id } = useParams();
  const [claimFiles, setClaimFiles] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openR, setOpenR] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false); // State for edit modal
  const [fileToEdit, setFileToEdit] = useState(null);
  const [, setNewFileType] = useState("");
  const [insuranceId, setInsuranceId] = useState("");
  const [fileId, setFileId] = useState("");
  const { register, handleSubmit } = useForm();

  const token = localStorage.getItem("cblife_claimForm_token");
  const parsedToken = JSON.parse(token);
  const jwtToken = parsedToken.jwttoken;

  console.log("id is===>", fileToEdit?.id);

  const handleRemove = async () => {
    try {
      await claimFileService.deleteCliamFile(jwtToken, fileToEdit); // Use fileToEdit.id for deletion
      homeAlert("Claim File has been removed.", false);
      setOpenR(false);
      setFileToEdit(null); // Reset the file to delete state
      fetchClaimfiles(); // Re-fetch claim files after deletion
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  const formData = new FormData();

  const handleEdit = async (data) => {
    console.log("Starting handleEdit with data:", data);

    // Append files to FormData
    Array.from(data.files).forEach((file, index) => {
      return formData.append("files", file);
    });

    // Append other fields
    formData.append("insuranceId", data.insuranceId);
    formData.append("id", data.id);

    console.log("Insurance ID appended:", data.insuranceId);
    console.log("ID appended:", data.id);

    try {
      // Assuming you want to update the file's name, you can update the logic here for other properties
      await claimFileService.updateClaimFile(
        jwtToken,
        fileToEdit?.id,
        formData
      );
      homeAlert("Claim File has been updated.", false);
      setOpenEditModal(false);
      setFileToEdit(null);
      fetchClaimfiles(); // Re-fetch files after edit
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  useEffect(() => {
    if (!claimFiles) {
      fetchClaimfiles();
    }
  }, [claimFiles]);

  const fetchClaimfiles = async () => {
    try {
      const res = await claimFileService.getCliamFile(
        instanceToken.token,
        insurance_id
      );
      setClaimFiles(res.data);
      setFilteredFiles(res.data); // Initialize filtered files
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    filterFiles(newValue);
  };

  const filterFiles = (tabIndex) => {
    if (!claimFiles) return;

    if (tabIndex === 0) {
      setFilteredFiles(claimFiles); // All files
    } else if (tabIndex === 1) {
      setFilteredFiles(
        claimFiles.filter((file) => file.type === "application/pdf")
      );
    } else if (tabIndex === 2) {
      setFilteredFiles(
        claimFiles.filter(
          (file) =>
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      );
    } else if (tabIndex === 3) {
      setFilteredFiles(claimFiles.filter((file) => file.type === "image/jpeg"));
    }
  };

  if (!claimFiles) {
    return <em>Loading...</em>;
  }

  const handleOpenR = (id) => {
    setFileToEdit(id); // Set the file ID to be deleted
    setOpenR(true);
  };

  const handleOpenEditModal = (file) => {
    setFileToEdit(file); // Set the file to be edited
    setNewFileType(file.type || ""); // Set the default file type
    setInsuranceId(file.insurance_id || ""); // Set the default insurance ID
    setFileId(file.id || ""); // Set the default file ID
    setOpenEditModal(true); // Open the edit modal
  };

  const handleCloseR = () => {
    setOpenR(false);
    setFileToEdit(null); // Reset the fileToDelete state if modal is closed without deletion
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setFileToEdit(null); // Reset the fileToEdit state if modal is closed without editing
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/claimFiles">
            ClaimFiles
          </Link>
          <Typography color="text.primary">
            ClaimFiles (ID - {insurance_id})
          </Typography>
        </Breadcrumbs>
      </div>

      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="All" />
          <Tab label="PDFs" />
          <Tab label="Word Documents" />
          <Tab label="Images" />
        </Tabs>
      </Box>

      {filteredFiles.map((row, index) => (
        <Container
          key={index}
          maxWidth={false}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Card
            sx={{
              minWidth: "80vw",
              minHeight: "60vh",
              display: "grid",
              gridTemplateColumns: "2fr 3fr",
            }}
          >
            <Box sx={{ ml: 2, p: 2, borderRadius: 2 }}>
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Insurance ID :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.secondary">
                    {row.insurance_id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Name :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.secondary">
                    {row.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Type :
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body2" color="text.secondary">
                    {row.type}
                  </Typography>
                </Grid>
              </Grid>
              <Button variant="contained" style={{ marginLeft: 30 }}>
                <a
                  href={`${row.file_path}`}
                  download={row.name}
                  className="downloadTag"
                >
                  Download file
                </a>
              </Button>

              <Button
                sx={{ m: "1rem" }}
                variant="contained"
                color="error"
                onClick={() => handleOpenR(row?.id)} // Open the modal for the file
              >
                Remove
              </Button>

              <Button
                sx={{ m: "1rem" }}
                variant="contained"
                onClick={() => handleOpenEditModal(row)} // Open the edit modal with the file data
              >
                Edit
              </Button>
            </Box>
          </Card>
        </Container>
      ))}

      {/* Delete Confirmation Modal */}
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to remove this file?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleRemove}>Confirm</Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="edit-modal-title" variant="h6" component="h2">
            Edit Claim File
          </Typography>

          {/* File Type */}
          <form onSubmit={handleSubmit(handleEdit)}>
            <input
              label="File Type"
              variant="outlined"
              fullWidth
              // value={newFileType} // The file type to be edited
              // onChange={(e) => setNewFileType(e.target.value)} // Update the file type
              {...register("files")}
              sx={{ mt: 2 }}
              type="file"
              id="files"
              multiple
              accept=".pdf,.docx,.jpg,.png"
            />

            {/* Insurance ID (Static text) */}
            <TextField
              label="Insurance ID"
              variant="outlined"
              fullWidth
              value={insuranceId} // Static value of the insurance ID
              {...register("insuranceId")}
              InputProps={{
                readOnly: true, // Make it read-only
              }}
              sx={{ mt: 2 }}
            />

            {/* File ID (Static text) */}
            <TextField
              label="File ID"
              variant="outlined"
              fullWidth
              value={fileId} // Static value of the file ID
              {...register("id")}
              InputProps={{
                readOnly: true, // Make it read-only
              }}
              sx={{ mt: 2 }}
            />
            {/* Save and Cancel Buttons */}
            <Box sx={{ textAlign: "right", mt: 2 }}>
              <Button color="secondary" onClick={handleCloseEditModal}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ClaimFile;
