import { z } from "zod";

const isSafeString = (value) => {
    const tagPattern = /<\/?[^>]+(>|$)/g; // Matches HTML tags
    if (tagPattern.test(value)) {
      return false;
    }
    return true;
};

export const createNewKnowledgeSchema = z.object({
  title_en: z
      .string({
        invalid_type_error: "English name must be a string!",
        required_error: "English name is required!",
      })
      .min(1, "English name must have at least one letter!")
      .refine(isSafeString, { message: "HTML tags and Js script are not allowed in English name!" }),
    title_mm: z
      .string({
        invalid_type_error: "Myanmar name must be a string!",
        required_error: "Myanmar name is required!",
      })
      .min(1, "Myanmar name must have at least one letter!")
      .refine(isSafeString, { message: "HTML tags are not allowed in Myanmar name!" }),
    paragraph_en: z
      .string({
        invalid_type_error: "Paragraph_en must be a string!",
        required_error: "Paragraph_en is required!",
      })
      .min(1, "Paragraph_en is required!"),
    paragraph_mm: z
      .string({
        invalid_type_error: "Paragraph_mm must be a string!",
        required_error: "Paragraph_mm is required!",
      })
      .min(1, "Paragraph_mm is required!")
      // .refine(isSafeString, { message: "HTML tags are not allowed in url_link!" }),
  });