import { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import { Link, useParams } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import newService from "../../../services/newService";
// import * as Marked from 'marked';
import imageUpload from "../../../services/upload";
import * as React from "react";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import { createNewPostSchema } from "../../../schema/createNewPostSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export default function UpdateNews({ homeAlert }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [titleEng, setTitleEng] = useState("");
  const [titleMM, setTitleMM] = useState("");
  const [category, setCategory] = useState("");
  const [parEng, setParEng] = useState(RichTextEditor.createEmptyValue());
  const [parMM, setParMM] = useState(RichTextEditor.createEmptyValue());
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const {
            register,
            handleSubmit,
            control,
            setValue,
            formState: { errors },
          } = useForm({
            resolver: zodResolver(createNewPostSchema),
            initialValues: {
              title_en : "",
              title_mm: "",
              paragraph_en : "<p></p>",
              paragraph_mm: "<p></p>",
              category : ""
            },
          });

  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    const path = IMAGE_PATH + result?.imageName;
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }
  useEffect(() => {
    if (!news) {
      fetchNews();
    }
  }, []);

  useEffect(() => {
    setTitleEng(news?.title_en);
    setTitleMM(news?.title_mm);
    setCategory(news?.category);
    setParEng(
      RichTextEditor.createValueFromString(news?.paragraph_en, "html")
    );
    setParMM(
      RichTextEditor.createValueFromString(news?.paragraph_mm, "html")
    );

    if (news) {
      setValue("title_en", news?.title_en);
      setValue("title_mm", news?.title_mm);
      setValue("paragraph_en", news?.paragraph_en);
      setValue("paragraph_mm", news?.paragraph_mm);
      setValue("category", news?.category);

      setValues({
        image_url: news?.image_url || "",
      });
    }
  }, [news]);

  const fetchNews = async () => {
    try {
      const res = await newService.getOneNews(instanceToken.token, id);
      console.log("res ", res);
      setNews(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeTitleMM = (e) => {
    setTitleMM(e.target.value.trim());
  };
  const changeTitleEng = (e) => {
    setTitleEng(e.target.value.trim());
  };

  const changeCategory = (e) => {
    setCategory(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];

      if (!imgFileTypes.includes(img.type)) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl(img);
    }
  };
  const formData = new FormData();

  const handleUpdate = async (data) => {

    formData.append("title_en", data?.title_en);
    formData.append("title_mm", data?.title_mm);
    formData.append("paragraph_en", data?.paragraph_en);
    formData.append("paragraph_mm", data?.paragraph_mm);
    formData.append("category", data?.category);
    formData.append("image_url", values?.image_url);
    formData.append("image_data", "");

    try {
      setLoading(true);
      if (imageFile) {
        await imageUpload.uploadImage(imageFileUrl, imageFile);
      }
      const response = await newService.putNews(
        instanceToken.token,
        news?.id,
        formData
      );
      navigate("/all_news");
      setNews(response.data);

      homeAlert("News Post have been Updated.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/all_news">News Post</Link>
          <span>Update News Post</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          {formData.forEach((value, index) => console.log(index))}
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={imagePreview ? imagePreview : values?.image_url}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}

              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  Rendered size must be 1920 * 1080 px and Aspect ratio must be
                  16:9
                </Typography>

                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>{errorMessage["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* title Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="title_en"
                  label="Title Eng"
                  {...register("title_en")}
                  onChange={changeTitleEng}
                  value={titleEng}
                  error={!!errors.title_en}
                  helperText={errors.title_en?.message}
                />
              </FormControl>
              {/* title MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="title_mm"
                  label="Title MM"
                  {...register("title_mm")}
                  onChange={changeTitleMM}
                  value={titleMM}
                  error={!!errors.title_mm}
                  helperText={errors.title_mm?.message}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* Category */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <InputLabel id="category">Category</InputLabel>
                <Select
                  labelId="category"
                  label="category"
                  defaultValue=""
                  {...register("category")}
                  onChange={changeCategory}
                  value={category}
                  error={!!errors.category}
                  helperText={errors.category?.message}
                  //error={errorMessage.category ? true : false}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  {/* <MenuItem value="Hospital_&_Clinic">
                    Hospital & Clinic
                  </MenuItem> */}
                  <MenuItem value="News">News</MenuItem>
                  <MenuItem value="Event">Event</MenuItem>
                  <MenuItem value="Promotion">Promotion</MenuItem>
                </Select>
                {/* {errorMessage.category && (
                  <FormHelperText error>{errorMessage.category}</FormHelperText>
                )} */}
                <FormHelperText error>{errorMessage["category"]}</FormHelperText>
              </FormControl>
            </Box>

            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                px: 3,
              }}
            >
              {/* paragraph Eng */}
              <Box>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph Eng
                </InputLabel>
                <Controller
                name="paragraph_en"
                control={control}
                render={() => (
                <>
              <RichTextEditor
                  value={parEng}
                  onChange={(value) => {
                  setParEng(value);
                  setValue("paragraph_en", value.toString("html").replace(/<[^>]*>/g, "").trim());
                  }}
              />
            {errors.paragraph_en && (
                <FormHelperText error>{errors.paragraph_en.message}</FormHelperText>
            )}
            </>
        )}
    />
                {/* {errorMessage.description && (
                  <FormHelperText error> {errorMessage.description}</FormHelperText>
                )} */}
              </Box>

              {/* paragraph MM*/}
              <Box sx={{ my: 2 }}>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph MM
                </InputLabel>
                <Controller
                name="paragraph_en"
                control={control}
                render={() => (
                  <>
                  <RichTextEditor
                      value={parMM}
                      onChange={(value) => {
                          setParMM(value);
                          setValue("paragraph_mm", value.toString("html").replace(/<[^>]*>/g, "").trim());
                      }}
                  />
                  {errors.paragraph_mm && (
                      <FormHelperText error>{errors.paragraph_mm.message}</FormHelperText>
                  )}
                  </>
        )}
    />
                {/* <FormHelperText error>{errorMessage["paragraph_mm"]}</FormHelperText> */}
                {/* {errorMessage.description && (
                  <FormHelperText error> {errorMessage.description}</FormHelperText>
                )} */}
              </Box>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
