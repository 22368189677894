import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import onboardingService from "../../../services/onboardings";
import imageUpload from "../../../services/upload";
import {
  TextField,
  FormControl,
  CardActions,
  CardMedia,
  Box,
  Typography,
  Card,
  CardContent,
  Breadcrumbs,
  Button,
  FormHelperText,
} from "@mui/material";
import { CreateOnboardingSchema } from "../../../schema/CreateOnboardingSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
export default function CreateOnboarding({ homeAlert }) {
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionMM, setDescriptionMM] = useState("");
  const [link, setLink] = useState("");
  const [, setOnboardings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: zodResolver(CreateOnboardingSchema),
      initialValues: {
        name_en : "",
        name_mm: "",
        description : "",
        description_mm: "",
        link: ""
      },
    });

  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    const path = IMAGE_PATH + result?.imageName;
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl(img);
    }
  };

  const formData = new FormData();
  const handleCreate = async (data) => {
    // e.preventDefault();
    formData.append("name_en", data?.name_en);
    formData.append("name_mm", data?.name_mm);
    formData.append("link", data?.link);
    formData.append("description", data?.description);
    formData.append("description_mm", data?.description_mm);
    formData.append("image_data", "");
    formData.append("image_url", values?.image_url);
    try {
      setLoading(true);
      await imageUpload.uploadImage(imageFileUrl, imageFile);
      const response = await onboardingService.postOnboarding(
        instanceToken.token,
        formData
      );
      navigate("/onboardings");
      setOnboardings(response.data);

      homeAlert("New Onboardings have been created.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/onboardings">Onboardings</Link>
          <span>Create Onboardins</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0 }}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                height="320"
                image={imagePreview}
                sx={{ my: 2 }}
              />

              <FormControl sx={{ my: 2 }}>
                <Typography
                  sx={{ fontSize: "12px", mb: 1, textAlign: "center" }}
                >
                  Rendered size must be 1920 * 1200 px and Aspect ratio must be
                  16: 10
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>{errorMessage["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                ml: "2rem",
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  // value={values.name}
                  {...register("name_en")}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="name_mm"
                  //  value={values.phone}
                  {...register("name_mm")}
                  error={!!errors.name_mm}
                  helperText={errors.name_mm?.message}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="description"
                  label="Description"
                  //  value={values.description}
                  {...register("description")}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="description_mm"
                  label="Description MM"
                  //  value={values.description}
                  {...register("description_mm")}
                  error={!!errors.description_mm}
                  helperText={errors.description_mm?.message}
                />
              </FormControl>

              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  // value={values.address}
                  {...register("link")}
                  error={!!errors.link}
                  helperText={errors.link?.message}
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
