import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import faqService from "../../../services/faqService";
import { instanceToken } from "../../../utils/constant";

export const categories = ["အမျိုးအစားများ", "အခြေခံ", "အခြား"];
export const categories_mm = ["types", "basic", "other"];
const categoriesValue = {
  types: "အမျိုးအစားများ",
  basic: "အခြေခံ",
  other: "အခြား",
};
export default function UpdateFaq({ homeAlert }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, setData] = useState([]);
  const [faq, setFaq] = useState(null);
  const [question, setQuestion] = useState("");
  const [questionMM, setQuestionMM] = useState("");
  const [answers, setAnswers] = useState([{ answer_en: "", answer_mm: "" }]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryMM, setCategoryMM] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    async function fetchFaq() {
      const response = await faqService.getAllFaq(instanceToken.token, id);
      setFaq(response.data);
      setAnswers(response?.data?.answers || [{ answer_en: "", answer_mm: "" }]);
      setCategory(response?.data?.category_en);
      setCategoryMM(response?.data?.category_mm);
      setQuestion(response?.data?.question_en);
      setQuestionMM(response?.data?.question_mm);
    }
    fetchFaq();
  }, [id]);

  const handleAddAnswer = () => {
    setAnswers([...answers, { answer_en: "", answer_mm: "" }]);
  };

  const handleAnswerChange = (index, field, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index][field] = value;
    setAnswers(updatedAnswers);
  };

  const handleRemoveAnswer = (index) => {
    const updatedAnswers = [...answers];
    updatedAnswers.splice(index, 1);
    setAnswers(updatedAnswers);
  };

  const handleUpdate = async () => {
    const err = {};

    if (!question.trim()) err.question = "Question is required";
    if (
      answers.some(
        (answer) => !answer.answer_en.trim() || !answer.answer_mm.trim()
      )
    ) {
      err.answers = "All answer fields must be filled";
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    try {
      setLoading(true);
      const response = await faqService.updateFaq(instanceToken.token, id, {
        question_mm: questionMM,
        question_en: question,
        category_en: category,
        category_mm: categoryMM,
        answers,
      });
      console.log(response);
      setData(response.data);
      homeAlert("FAQ has been updated.", false);
      navigate("/faqs");
    } catch (error) {
      homeAlert("Failed to update FAQ!", true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/faqs">FAQs</Link>
          <span>Edit FAQ</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            {/* Category Select */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <InputLabel id="category">Category English</InputLabel>
                <Select
                  labelId="category"
                  value={category || ""}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setCategoryMM(categoriesValue[e.target.value] || "");
                    setErrors({});
                  }}
                  error={!!errors.category}
                >
                  <MenuItem value="" disabled>
                    Select Category
                  </MenuItem>
                  <MenuItem value="types">Types</MenuItem>
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )}
              </FormControl>

              {/* Category Myanmar Select */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <InputLabel id="category_mm">Category Myanmar</InputLabel>
                <Select
                  labelId="category_mm"
                  value={categoryMM || ""}
                  onChange={(e) => {
                    setCategoryMM(e.target.value);
                    const selectedCategory = Object.keys(categories).find(
                      (key) => categoriesValue[key] === e.target.value
                    );
                    setCategory(selectedCategory || "");
                    setErrors({});
                  }}
                  error={!!errors.categoryMM}
                >
                  <MenuItem value="" disabled>
                    Select Category
                  </MenuItem>
                  <MenuItem value="အမျိုးအစားများ">အမျိုးအစားများ</MenuItem>
                  <MenuItem value="အခြေခံ">အခြေခံ</MenuItem>
                  <MenuItem value="အခြား">အခြား</MenuItem>
                </Select>
                {errors.categoryMM && (
                  <FormHelperText error>{errors.categoryMM}</FormHelperText>
                )}
              </FormControl>
            </Box>
            {/* Question Inputs */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="question"
                  label="Question"
                  value={question || ""}
                  onChange={(e) => setQuestion(e.target.value.trim())}
                  error={!!errors.question}
                  helperText={errors.question}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="question_mm"
                  label="Question Myanmar"
                  value={questionMM || ""}
                  onChange={(e) => setQuestionMM(e.target.value.trim())}
                  error={!!errors.questionMM}
                  helperText={errors.questionMM}
                />
              </FormControl>
            </Box>
            {/* Answer Fields */}
            {answers.map((a, index) => (
              <Box
                key={index}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  px: 3,
                  my: 3,
                  columnGap: 5,
                }}
              >
                <TextField
                  label={`Answer ${index + 1} (English)`}
                  value={a.answer_en}
                  onChange={(e) =>
                    handleAnswerChange(index, "answer_en", e.target.value)
                  }
                  error={!!errors.answers}
                  helperText={errors.answers}
                />
                <TextField
                  label={`Answer ${index + 1} (Myanmar)`}
                  value={a.answer_mm}
                  onChange={(e) =>
                    handleAnswerChange(index, "answer_mm", e.target.value)
                  }
                  error={!!errors.answers}
                  helperText={errors.answers}
                />
                {answers.length > 1 && (
                  <Button
                    variant="contained"
                    sx={{ mt: 0, mb: 2 }}
                    onClick={() => handleRemoveAnswer(index)}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      padding: "14px 0",
                      cursor: "pointer",
                    }}
                  >
                    Remove Answer
                  </Button>
                )}
              </Box>
            ))}

            {/* Add Answer Button */}
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button
                onClick={handleAddAnswer}
                variant="contained"
                style={{ padding: "8px 16px" }}
              >
                + Add Answer
              </Button>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
