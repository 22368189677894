import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const findAll = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/popup-messages?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const findOne = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/popup-messages/${id}`,
    config
  );
  return response;
};

const create = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}api/popup-messages`,
    data,
    config
  );
  return response;
};
const update = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.patch(
    `${BACKEND_URL}api/popup-messages/${id}`,
    data,
    config
  );
  return response;
};
const remove = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}api/popup-messages/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findAll,
  findOne,
  create,
  update,
  remove,
};
