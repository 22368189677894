import React, { useState } from "react";
import jwt from "jwt-decode";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { Card, Alert, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loginService from "../services/login";
import logo from "../assets/icons/cblifelogo.png";
const Login = ({ homeALert }) => {
  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    username: "",
    password: "",
    showPassword: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    username: "",
    password: "",
  });
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrors({});
    let err = {};

    // Validate input fields
    if (!values.username) {
      err.username = "Please enter your username";
    }
    if (!values.password) {
      err.password = "Please enter your password";
    }
    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    try {
      setLoading(true);
      const response = await loginService.login({
        username: values.username,
        password: values.password,
      });

      // Decode the JWT token
      const decodedToken = jwt(response.data.token);

      const currentTime = Date.now() / 1000;

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        console.log("decoded token ", decodedToken?.exp);
        console.log("curretn time ", currentTime);
        console.log("session expire-------------");
        setShowAlert({
          message: "Session has expired. Please login again.",
          isError: true,
        });
        setLoading(false);
        setTimeout(() => setShowAlert({ message: "", isError: false }), 3000);
        setLoading(false);
        return;
      }

      // Store token in local storage
      const data = JSON.stringify({
        decodedToken,
        token: response.data.token,
      });
      window.localStorage.setItem("cblife_logged_admin", data);
      navigate("/all_news");
    } catch (error) {
      console.log("Login Error", error);

      if (error.response?.data?.error) {
        setErrors({
          username: error.response.data.error.includes("username")
            ? error.response.data.error
            : "",
          password: error.response.data.error.includes("password")
            ? error.response.data.error
            : "",
        });

        setShowAlert({
          message: error.response.data.error,
          isError: true,
        });
        setTimeout(() => setShowAlert({ message: "", isError: false }), 3000);
      } else {
        setShowAlert({
          message: "An unexpected error occurred. Please try again.",
          isError: true,
        });
        setTimeout(() => setShowAlert({ message: "", isError: false }), 3000);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm" className="logo">
        <Box
          // sx={{
          //   height: "100vh",
          //   width: "100vw",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   textAlign: "center",
          //   bgcolor: "#ccc",
          //   margin: 0,
          // }}
          sx={{
            height: "100vh",
            display: "flex",

            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            // bgcolor: "rgb(199, 221, 233)",
            color: "#000",
          }}
        >
          {showAlert.message && (
            <Alert
              severity={showAlert.isError ? "error" : "success"}
              sx={{ mb: 2 }}
            >
              {showAlert.message}
            </Alert>
          )}

          <Box>
            <FormControl
              sx={{
                p: 1.4,
                width: 250,
                //backgroundColor: "#fff",
              }}
            >
              <img alt="cblife logo" src={logo} />
            </FormControl>
            <Typography variant="h4" fontWeight="bold" component="p">
              CBLife Admin Panel
            </Typography>
            <Typography variant="subtitle1" component="p" sx={{ mb: 2 }}>
              Enter your credentials to continue
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl sx={{ m: 2, width: "300px" }} variant="outlined">
              <TextField
                id="username"
                label="Username"
                value={values.username}
                onChange={handleChange("username")}
                error={!!errors.username}
                helperText={errors.username}
              />
            </FormControl>
            <FormControl sx={{ m: 2, width: "300px" }} variant="outlined">
              <TextField
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Password"
                error={!!errors.password}
                helperText={errors.password}
              />
            </FormControl>
            <FormControl sx={{ m: 2 }}>
              <LoadingButton
                onClick={handleLogin}
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: "#4b26d1",
                  width: 100,
                  margin: "0 auto",
                }}
                size="medium"
              >
                Login
              </LoadingButton>
            </FormControl>
          </Box>
          {/* <Typography
            variant="span"
            component="span"
            sx={{ fontSize: "0.8rem", p: 3 }}
          >
            Version 1.0.0
          </Typography> */}
        </Box>
      </Container>
    </>
  );
};

export default Login;
