import axios from "axios";
import { BACKEND_URL, BACKEND_URL_CLAIM } from "../utils/constant";

const getCliamFiles = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/claim_files?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getCliamFile = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/claim_files/${id}`,
    config
  );
  return response;
};

const searchCliamFile = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/claim_files/${id}`,
    config
  );
  return response;
};

const deleteCliamFile = async (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(
    `${BACKEND_URL_CLAIM}cblcust/ClaimNewStruts/${id}`,
    config
  );
  return response;
};

const updateClaimFile = async (token, id, formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BACKEND_URL_CLAIM}cblcust/ClaimNewStruts/${id}`,
    formData,
    config
  );
  return response;
};

export default {
  getCliamFiles,
  getCliamFile,
  searchCliamFile,
  deleteCliamFile,
  updateClaimFile,
};
