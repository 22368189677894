import React, { useEffect, useState } from "react";
import partnerService from "../../../services/partners";
import { instanceToken } from "../../../utils/constant";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Breadcrumbs,
  Button,
  TableBody,
  Avatar,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { TableRowsRounded } from "@mui/icons-material";
import inquirySevice from "../../../services/inquirySevice";

export default function Inquiry() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [inquiry, setInquiry] = useState(null);

  useEffect(() => {
    fetchInquiry(rowsPerPage, offset);
  }, [offset, rowsPerPage]);

  const fetchInquiry = async (limit, offset) => {
    try {
      const res = await inquirySevice.getAllInquiry(
        instanceToken.token,
        limit,
        offset
      );

      parse(res.data);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    
    setInquiry(data.inquiry);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!inquiry) {
    return <em>Loading</em>;
  }

  //Change data from String to object with JSON
  // if (typeof res.data.address !== "object") {
  //   try {
  //     let address = JSON.parse(res.data.address);
  //     user = { ...result.data.users_by_pk, address };
  //   } catch (e) {
  //     user = { ...result.data.users_by_pk };
  //   }
  // } else user = { ...result.data.users_by_pk, address: "-" };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>Inquiry </span>
        </Breadcrumbs>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple-table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell>Image</TableCell> */}
              <TableCell>Product Name</TableCell>
              <TableCell>Inquirer Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inquiry.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                {/* <TableCell>
                  <Avatar
                    alt="routine image"
                    src={`data:image/png;base64,${row.image_data}`}
                    width="56px"
                    height="56px"
                  />
                </TableCell> */}
                <TableCell>{row.product_name}</TableCell>
                <TableCell>{row.inquirer_name}</TableCell>
                <TableCell>{row.phone_number}</TableCell>
                <TableCell>{row.message.substring(0, 20)}</TableCell>

                <TableCell>{row.created_at}</TableCell>
                <TableCell>{row.updated_at}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    component={Link}
                    color="secondary"
                    to={`/inquiry/${row.id}`}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
