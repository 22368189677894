import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getAllFaqs = async (token, limit, offset) => {
  console.log("service", limit, offset);
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/faq?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};
const getAllFaq = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}api/faq/${id}`, config);
  return response;
};
const deleteFaq = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(`${BACKEND_URL}api/faq/${id}`, config);
  return response;
};
const createFaq = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}api/faq`, data, config);
  return response;
};
const updateFaq = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.patch(
    `${BACKEND_URL}api/faq/${id}`,
    data,
    config
  );
  return response;
};
export default {
  getAllFaqs,
  getAllFaq,
  deleteFaq,
  createFaq,
  updateFaq,
};
