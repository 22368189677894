import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import faqService from "../../../services/faqService";
import { instanceToken } from "../../../utils/constant";

const categories = {
  types: "အမျိုးအစားများ",
  basic: "အခြေခံ",
  other: "အခြား",
};

export default function CreateFaq({ homeAlert }) {
  const navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [questionMM, setQuestionMM] = useState("");
  const [answers, setAnswers] = useState([{ answer_en: "", answer_mm: "" }]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryMM, setCategoryMM] = useState("");
  const [errors, setErrors] = useState({});

  const handleAddAnswer = () => {
    setAnswers([...answers, { answer_en: "", answer_mm: "" }]);
  };

  const handleAnswerChange = (index, field, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index][field] = value;
    setAnswers(updatedAnswers);
  };

  const handleRemoveAnswer = (index) => {
    const updatedAnswers = [...answers];
    updatedAnswers.splice(index, 1);
    setAnswers(updatedAnswers);
  };

  const handleCreate = async () => {
    const err = {};

    if (!question.trim()) err.question = "Question in English is required.";
    if (!questionMM.trim()) err.questionMM = "Question in Myanmar is required.";
    if (!category) err.category = "Category is required.";
    if (
      answers.some(
        (answer) => !answer.answer_en.trim() || !answer.answer_mm.trim()
      )
    ) {
      err.answers = "Both English and Myanmar answers are required.";
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    try {
      setLoading(true);
      const data = {
        question_mm: questionMM,
        question_en: question,
        category_en: category,
        category_mm: categoryMM,
        answers: answers,
      };
      console.log(data);

      // Example API call
      await faqService.createFaq(instanceToken.token, data);
      homeAlert("FAQ has been created.", false);
      navigate("/faqs");
    } catch (error) {
      homeAlert("Failed to create FAQ!", true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBottom: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/faqs">FAQs</Link>
          <span>Create FAQ</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            {/* Category Selection */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              <FormControl sx={{ my: 2 }}>
                <InputLabel id="category">Category (English)</InputLabel>
                <Select
                  labelId="category"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setCategoryMM(categories[e.target.value] || "");
                    setErrors({});
                  }}
                  error={!!errors.category}
                >
                  <MenuItem value="" disabled>
                    Select Category
                  </MenuItem>
                  <MenuItem value="types">Types</MenuItem>
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ my: 2 }}>
                <InputLabel id="categoryMM">Category (Myanmar)</InputLabel>
                <Select
                  labelId="categoryMM"
                  value={categoryMM}
                  onChange={(e) => {
                    const selectedCategory = Object.keys(categories).find(
                      (key) => categories[key] === e.target.value
                    );
                    setCategory(selectedCategory || "");
                    setCategoryMM(e.target.value);
                    setErrors({});
                  }}
                  error={!!errors.categoryMM}
                >
                  <MenuItem value="" disabled>
                    Select Category
                  </MenuItem>
                  {Object.values(categories).map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Questions */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              <FormControl sx={{ my: 2 }}>
                <TextField
                  label="Question (English)"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  error={!!errors.question}
                  helperText={errors.question}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }}>
                <TextField
                  label="Question (Myanmar)"
                  value={questionMM}
                  onChange={(e) => setQuestionMM(e.target.value)}
                  error={!!errors.questionMM}
                  helperText={errors.questionMM}
                />
              </FormControl>
            </Box>

            {/* Answers */}
            {answers.map((answer, index) => (
              <Box
                key={index}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  px: 3,
                  columnGap: 5,
                }}
              >
                <FormControl sx={{ my: 2 }}>
                  <TextField
                    label={`Answer EN ${index + 1}`}
                    value={answer.answer_en}
                    onChange={(e) =>
                      handleAnswerChange(index, "answer_en", e.target.value)
                    }
                    error={!!errors.answers && !answer.answer_en.trim()}
                  />
                </FormControl>
                <FormControl sx={{ my: 2 }}>
                  <TextField
                    label={`Answer MM ${index + 1}`}
                    value={answer.answer_mm}
                    onChange={(e) =>
                      handleAnswerChange(index, "answer_mm", e.target.value)
                    }
                    error={!!errors.answers && !answer.answer_mm.trim()}
                  />
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, mb: 4 }}
                    onClick={() => handleRemoveAnswer(index)}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      padding: "14px 0",
                      cursor: "pointer",
                    }}
                  >
                    Delete
                  </Button>
                </FormControl>
              </Box>
            ))}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              <Button
                variant="contained"
                onClick={handleAddAnswer}
                style={{ padding: "8px 16px", marginTop: "10px" }}
              >
                + Add Answer
              </Button>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "flex-end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
