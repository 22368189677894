import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
import { Token } from "@mui/icons-material";

const getAllBanners = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/banner_images?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getOneBanner = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/banner_images/${id}`,
    config
  );
  return response;
};

const deleteBanner = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}api/banner_images/${id}`,
    config
  );
  return response;
};

const postBanner = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}api/banner_images`,
    data,
    config
  );
  return response;
};

const putBanner = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}api/banner_images/${id}`,
    data,
    config
  );
  return response;
};
export default {
  getAllBanners,
  getOneBanner,
  deleteBanner,
  postBanner,
  putBanner,
};
