import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import knowledgeService from "../../../services/knowledgeService";
import imageUpload from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Typography,
  CardMedia,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RichTextEditor from "react-rte";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createNewKnowledgeSchema } from "../../../schema/createNewKnowledgeSchema";
import { z } from "zod";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateKnowledge = ({ homeAlert }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [titleEng, setTitleEng] = useState("");
  const [titleMM, setTitleMM] = useState("");
  const [parEng, setParEng] = useState(RichTextEditor.createEmptyValue());
  const [parMM, setParMM] = useState(RichTextEditor.createEmptyValue());
  const [errorMessage, setErrorMessage] = useState({});

  const [knowledge, setknowledge] = useState(null);

  const {
          register,
          handleSubmit,
          control,
          setValue,
          formState: { errors },
        } = useForm({
          resolver: zodResolver(createNewKnowledgeSchema),
          initialValues: {
            title_en : "",
            title_mm: "",
            paragraph_en : "<p></p>",
            paragraph_mm: "<p></p>",
          },
        });

  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    console.log(result);
    const path = IMAGE_PATH + result?.imageName;
    console.log("path=============>", path);
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }
  useEffect(() => {
    if (!knowledge) {
      fetchknowledge();
    }
  }, []);

  useEffect(() => {
      setTitleEng(knowledge?.title_en);
      setTitleMM(knowledge?.title_mm);
      setParEng(
        RichTextEditor.createValueFromString(knowledge?.paragraph_en, "html")
      );
      setParMM(
        RichTextEditor.createValueFromString(knowledge?.paragraph_mm, "html")
      );

    if (knowledge) {
        setValue("title_en", knowledge?.title_en);
        setValue("title_mm", knowledge?.title_mm);
        setValue("paragraph_en", knowledge?.paragraph_en);
        setValue("paragraph_mm", knowledge?.paragraph_mm);

      
      setValues({
        image_url: knowledge?.image_url || "",
      });
    }
  }, [knowledge,setValue]);

  const fetchknowledge = async () => {
    try {
      const res = await knowledgeService.getKnowledge(instanceToken.token, id);
      setknowledge(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeTitleMM = (e) => {
    setTitleMM(e.target.value.trim());
  };
  const changeTitleEng = (e) => {
    setTitleEng(e.target.value.trim());
  };

//   const handleParagraphEng = (value) => {
//     const cleanedText = value.toString('html').replace(/<[^>]+>/g, '').trim(); // Remove HTML tags
//     setParEng(value);
//     setValue('paragraph_en', cleanedText);
// };

// const handleParagraphMM = (value) => {
//   const cleanedText = value.toString('html').replace(/<[^>]+>/g, '').trim();
//   setParMM(value);
//   setValue('paragraph_mm', cleanedText);
// };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrorMessage({
          ...errorMessage,
          profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrorMessage({
          ...errorMessage,
          profile: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl(img);
    }
  };

  const formData = new FormData();


  const handleUpdate = async (data) => {
    formData.append("title_en", data?.title_en);
    formData.append("title_mm", data?.title_mm);
    formData.append("paragraph_en", data?.paragraph_en);
    formData.append("paragraph_mm", data?.paragraph_mm);
    formData.append("image_url", values?.image_url);
    formData.append("image_data", "");

    try {
      setLoading(true);
      console.log(formData);
      if (imageFile) {
        await imageUpload.uploadImage(imageFileUrl, imageFile);
      }
      const response = await knowledgeService.putKnowledge(
        instanceToken.token,
        knowledge?.id,
        formData
      );
      navigate("/knowledges");
      console.log("response ", response);
      setknowledge(response.data);
      homeAlert("knowledge Post have been updated.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };

  return (
      <form onSubmit={handleSubmit(handleUpdate)}>
        <div role="presentation" style={{ marginBlockEnd: "10px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/knowledges">Knowledges</Link>
            <Link to={`/knowledges/${id}`}>Knowledge (ID - {id})</Link>
            <span>Update</span>
          </Breadcrumbs>
        </div>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ minWidth: "80vw" }}>
            <CardContent
              sx={{ display: "flex", pb: 0, flexDirection: "column" }}
            >
              <Box
                sx={{
                  maxWidth: "40vw",
                  display: "grid",
                  justifyContent: "center",
                  // bgcolor: "red",
                  ml: 33,
                  borderRadius: 2,
                  boxShadow: 2,
                }}
              >
                <CardMedia
                  component="img"
                  height="320"
                  image={imagePreview ? imagePreview : values?.image_url}
                  sx={{ my: 2 }}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {/* image */}
                <FormControl sx={{ my: 2, maxWidth: 400 }}>
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ p: 2 }}
                  >
                    <PhotoCamera />
                    <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                    <input
                      hidden
                      onChange={changeFile}
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                      type="file"
                    />
                  </Button>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: 5,
                }}
              >
                {/* title Eng */}
                <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                  <TextField
                    id="title_en"
                    label="Title Eng"
                    {...register("title_en")}
                    onChange={changeTitleEng}
                    value={titleEng}
                    error={!!errors.title_en}
                    helperText={errors.title_en?.message}
                    // error={errorMessage[index]}
                    // FormHelperText={errorMessage[index]}
                  />
                </FormControl>
                {/* title MM */}
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    id="title_mm"
                    label="Title MM"
                    {...register("title_mm")}
                    onChange={changeTitleMM}
                    value={titleMM}
                    error={!!errors.title_mm}
                    helperText={errors.title_mm?.message}
                    // error={errorMessage[index]}
                    // FormHelperText={errorMessage[index]}
                  />
                </FormControl>
              </Box>
              {/* <Box
                sx={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "column",
                  px: 3,
                }}
              >
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    sx={{ overflowY: "scroll", maxHeight: "20rem" }}
                    multiline
                    id="paragraph_en"
                    label="Paragraph Eng"
                    value={parEng}
                    onChange={handleChangeText}
                    // error={errorMessage[index]}
                    // FormHelperText={errorMessage[index]}
                  />
                </FormControl>
                <FormControl sx={{ my: 2 }} variant="outlined">
                  <TextField
                    sx={{ overflowY: "scroll", maxHeight: "20rem" }}
                    multiline={Infinity}
                    id="paragraph_mm"
                    label="Paragraph MM"
                    value={parMM}
                    onChange={handleChangeTextMM}
                    // error={errorMessage[index]}
                    // FormHelperText={errorMessage[index]}
                  />
                </FormControl>
              </Box> */}
              <Box>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph Eng
                </InputLabel>
                <Controller
                name="paragraph_en"
                control={control}
                render={() => (
                <>
              <RichTextEditor
                  value={parEng}
                  onChange={(value) => {
                      setParEng(value);
                      setValue("paragraph_en", value.toString("html").replace(/<[^>]*>/g, "").trim());
                  }}
              />
            {errors.paragraph_en && (
                <FormHelperText error>{errors.paragraph_en.message}</FormHelperText>
            )}
            </>
        )}
    />
                {/* {errorMessage.description && (
                  <FormHelperText error> {errorMessage.description}</FormHelperText>
                )} */}
                {/* <FormHelperText error>{errorMessage["paragraph_en"]}</FormHelperText> */}
              </Box>
              {/* paragraph MM*/}
              <Box sx={{ my: 2 }}>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph MM
                </InputLabel>
                <Controller
                name="paragraph_en"
                control={control}
                render={() => (
                  <>
                  <RichTextEditor
                      value={parMM}
                      onChange={(value) => {
                          setParMM(value);
                          setValue("paragraph_mm", value.toString("html").replace(/<[^>]*>/g, "").trim());
                      }}
                  />
                  {errors.paragraph_mm && (
                      <FormHelperText error>{errors.paragraph_mm.message}</FormHelperText>
                  )}
                  </>
        )}
    />
              </Box>
            </CardContent>
            ;
            <CardActions sx={{ justifyContent: "end" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
              >
                Update
              </LoadingButton>
            </CardActions>
          </Card>
        </Box>
      </form>
  );
};

export default UpdateKnowledge;
