import { z } from "zod";

const isSafeString = (value) => {
    const tagPattern = /<\/?[^>]+(>|$)/g; // Matches HTML tags
    if (tagPattern.test(value)) {
      return false;
    }
    return true;
    };

export const createNewBannerSchema = z.object({
    name_en: z
      .string({
        invalid_type_error: "English name must be a string!",
        required_error: "English name is required!",
      })
      .min(1, "English name must have at least one letter!")
      .refine(isSafeString, { message: "HTML tags and Js script are not allowed in English name!" }),
    name_mm: z
      .string({
        invalid_type_error: "Myanmar name must be a string!",
        required_error: "Myanmar name is required!",
      })
      .min(1, "Myanmar name must have at least one letter!")
      .refine(isSafeString, { message: "HTML tags are not allowed in Myanmar name!" }),
    link: z
      .string({
        invalid_type_error: "Link must be a string!",
        required_error: "Url_link is required!",
      })
      .min(1, "Url_link is required!")
      .refine(isSafeString, { message: "HTML tags are not allowed in url_link!" }),
  });