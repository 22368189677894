import React, { useEffect, useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import onboardingService from "../../../services/onboardings";
import {
  TextField,
  FormControl,
  CardActions,
  CardMedia,
  Box,
  Typography,
  Card,
  CardContent,
  Breadcrumbs,
  Button,
  FormHelperText,
} from "@mui/material";
import imageUpload from "../../../services/upload";
import { CreateOnboardingSchema } from "../../../schema/CreateOnboardingSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
export default function UpdateOnboarding({ homeAlert }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [nameEng, setNameEng] = useState("");
  const [nameMM, setNameMM] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionMM, setDescriptionMM] = useState("");
  const [link, setLink] = useState("");
  const [onboardings, setOnboardings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm({
        resolver: zodResolver(CreateOnboardingSchema),
        initialValues: {
          name_en : "",
          name_mm: "",
          description : "",
          description_mm: "",
          link: ""
        },
      });
  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    const path = IMAGE_PATH + result?.imageName;
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }
  useEffect(() => {
    if (!onboardings) {
      fetchonboardings();
    }
  }, []);

  useEffect(() => {
    setNameMM(onboardings?.name_mm);
    setNameEng(onboardings?.name_en);
    setLink(onboardings?.link);
    setDescription(onboardings?.description);
    setDescriptionMM(onboardings?.description_mm);

    if (onboardings) {
        setValue("name_en", onboardings?.name_en);
        setValue("name_mm", onboardings?.name_mm);
        setValue("link", onboardings?.link);
        setValue("description", onboardings?.description);
        setValue("description_mm", onboardings?.description_mm);

      setValues({
        image_url: onboardings?.image_url || "",
      });
    }
  }, [onboardings,setValue]);

  const fetchonboardings = async () => {
    try {
      const res = await onboardingService.getOnboarding(
        instanceToken.token,
        id
      );
      setOnboardings(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const changeNameMM = (e) => {
    setNameMM(e.target.value);
  };
  const changeNameEng = (e) => {
    setNameEng(e.target.value);
  };

  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const changeDescription = (e) => {
    setDescription(e.target.value);
  };

  const changeDescriptionMM = (e) => {
    setDescriptionMM(e.target.value);
  };

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrorMessage({
          ...errorMessage,
          image_data: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrorMessage({
          ...errorMessage,
          image_data: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl(img);
    }
  };

  const formData = new FormData();
  const handleUpdate = async (data) => {

    formData.append("name_en", data?.name_en);
    formData.append("name_mm", data?.name_mm);
    formData.append("link", data?.link);
    formData.append("description", data?.description);
    formData.append("description_mm", data?.description_mm);
    formData.append("image_data", "");
    formData.append("image_url", values?.image_url);

    try {
      setLoading(true);
      if (imageFile) {
        await imageUpload.uploadImage(imageFileUrl, imageFile);
      }
      const response = await onboardingService.putOnboarding(
        instanceToken.token,
        id,
        formData
      );
      navigate("/onboardings");
      setOnboardings(response.data);

      homeAlert("New Onboardings have been updated.", false);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/onboardings">Onboardings</Link>
          <span>Update Onboardins</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0 }}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <CardMedia
                component="img"
                height="320"
                image={imagePreview ? imagePreview : values?.image_url}
                sx={{ my: 2 }}
              />

              <FormControl sx={{ my: 2 }}>
                <Typography
                  sx={{ fontSize: "12px", mb: 1, textAlign: "center" }}
                >
                  Rendered size must be 1920 * 1200 px and Aspect ratio must be
                  16: 10
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                  />
                </Button>
                <FormHelperText error>{errorMessage["image_data"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                ml: "2rem",
              }}
            >
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_en"
                  label="Name Eng"
                  {...register("name_en")}
                  onChange={changeNameEng}
                  value={nameEng}
                  error={!!errors.name_en}
                  helperText={errors.name_en?.message}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="name_mm"
                  label="name_mm"
                  {...register("name_mm")}
                  onChange={changeNameMM}
                  value={nameMM}
                  error={!!errors.name_mm}
                  helperText={errors.name_mm?.message}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="description"
                  label="Description"
                  {...register("description")}
                  onChange={changeDescription}
                  value={description}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="description_mm"
                  label="Description MM"
                  {...register("description_mm")}
                  onChange={changeDescriptionMM}
                  value={descriptionMM}
                  error={!!errors.description_mm}
                  helperText={errors.description_mm?.message}
                />
              </FormControl>

              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="link"
                  label="Link"
                  {...register("link")}
                  onChange={changeLink}
                  value={link}
                  error={!!errors.link}
                  helperText={errors.link?.message}
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
