import { z } from "zod";

const isSafeString = (value) => {
  const tagPattern = /<\/?[^>]+(>|$)/g; // Matches HTML tags
  if (tagPattern.test(value)) {
    return false;
  }
  return true;
};

export const CreateNewPrivilegeSchema = z.object({
  name_en: z
    .string({
      invalid_type_error: "English name must be a string!",
      required_error: "English name is required!",
    })
    .min(1, "English name must have at least one letter!")
    .refine(isSafeString, {
      message: "HTML tags and Js script are not allowed in English name!",
    }),

  name_mm: z
    .string({
      invalid_type_error: "Myanmar name must be a string!",
      required_error: "Myanmar name is required!",
    })
    .min(1, "Myanmar name must have at least one letter!")
    .refine(isSafeString, {
      message: "HTML tags are not allowed in Myanmar name!",
    }),

  link: z
    .string({
      invalid_type_error: "Link must be a string!",
      required_error: "Url_link is required!",
    })
    .min(1, "Url_link is required!")
    .refine(isSafeString, {
      message: "HTML tags are not allowed in url_link!",
    }),

  address_en: z
    .string({
      invalid_type_error: "address_en must be a string!",
      required_error: "address_en is required!",
    })
    .min(1, "address_en is required!")
    .refine(isSafeString, {
      message: "HTML tags are not allowed in address_en!",
    }),

  address_mm: z
    .string({
      invalid_type_error: "address_mm must be a string!",
      required_error: "address_mm is required!",
    })
    .min(1, "address_mm is required!")
    .refine(isSafeString, {
      message: "HTML tags are not allowed in address_mm!",
    }),

  phone: z
    .string({
      invalid_type_error: "phone must be a number!",
      required_error: "phone is required!",
    })

    .refine(isSafeString, { message: "HTML tags are not allowed in phone!" }),

  phone_note: z
    .string({
      invalid_type_error: "phone_note must be a string!",
      required_error: "phone_note is required!",
    })
    .min(1, "phone_note is required!")
    .refine(isSafeString, {
      message: "HTML tags are not allowed in phone_note!",
    }),

  category: z
    .string({
      invalid_type_error: "category must be a string!",
      required_error: "category is required!",
    })
    .min(1, "category is required!")
    .refine(isSafeString, {
      message: "HTML tags are not allowed in category!",
    }),
});
