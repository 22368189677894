import { useState } from "react";
import RichTextEditor from "react-rte";
import { Link } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import imageUpload from "../../../services/upload";
// import * as Marked from 'marked';
import * as React from "react";
// import dynamic from "next/dynamic";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Typography,
  InputLabel,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import knowledgeService from "../../../services/knowledgeService";
import { IMAGE_PATH, instanceToken } from "../../../utils/constant";
import { createNewKnowledgeSchema } from "../../../schema/createNewKnowledgeSchema";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export default function CreateKnowledge({ homeAlert }) {
  const navigate = useNavigate();

  const [titleEng, setTitleEng] = useState("");
  const [titleMM, setTitleMM] = useState("");
  const [parEng, setParEng] = useState(RichTextEditor.createEmptyValue());
  const [parMM, setParMM] = useState(RichTextEditor.createEmptyValue());

  const [imagePreview, setImagePreview] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [values, setValues] = useState({ image_url: "" });
  const [, setKnowledges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
      } = useForm({
        resolver: zodResolver(createNewKnowledgeSchema),
        initialValues: {
          title_en : "",
          title_mm: "",
          paragraph_en : "<p></p>",
          paragraph_mm: "<p></p>",
        },
      });

  const handleParagraphEng = (value) => {
    setParEng(value);
    setValue('paragraph_en', value.toString('html'));  // Converts content to HTML string
  };

  const handleParagraphMM = (value) => {
    setParMM(value);
    setValue('paragraph_mm', value.toString('html'));  // Converts content to HTML string
  };
  async function getImageUrl(imgData) {
    const response = await imageUpload.getImageUrl(
      instanceToken.token,
      imgData
    );
    const result = response.data;
    const path = IMAGE_PATH + result?.imageName;
    setImageFileUrl(result?.imageUploadUrl);
    setValues({
      ...values,
      image_url: path,
    });
  }

  const changeFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];

      if (!imgFileTypes.includes(img.type)) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 204800) {
        setErrorMessage({
          ...errorMessage,
          image_url: "Image file size must be smaller than 200KB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl(img);
    }
  };

  const formData = new FormData();

  const handleCreate = async (data) => {
    formData.append("title_en", data?.title_en);
    formData.append("title_mm", data?.title_mm);
    formData.append("paragraph_en", data?.paragraph_en);
    formData.append("paragraph_mm", data?.paragraph_mm);
    formData.append("image_url", values?.image_url);
    formData.append("image_data", "");

    try {
      setLoading(true);
      await imageUpload.uploadImage(imageFileUrl, imageFile);
      const response = await knowledgeService.postKnowledge(
        instanceToken.token,
        formData
      );

      navigate("/knowledges");
      setKnowledges(response.data);
      homeAlert("News Knowledges have been created.", false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server.", true);
    } finally {
      setLoading(false);
      setErrorMessage({});
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreate)}>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/knowledges">Knowledges</Link>
          <span>Create Knowledges</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "80vw" }}>
          <CardContent sx={{ display: "flex", pb: 0, flexDirection: "column" }}>
            <Box
              sx={{
                maxWidth: "40vw",
                display: "grid",
                justifyContent: "center",
                // bgcolor: "red",
                ml: 31,
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="320"
                image={imagePreview}
                sx={{ my: 2 }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* image */}
              <FormControl sx={{ my: 2, maxWidth: 400 }}>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Rendered size must be 1920 * 1080 px and Aspect ratio must be
                  16:9
                </Typography>
                <Button
                  variant="contained"
                  color="warning"
                  component="label"
                  size="large"
                >
                  <PhotoCamera />
                  <Typography sx={{ ml: 1 }}>Upload Image</Typography>
                  <input
                    hidden
                    onChange={changeFile}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    type="file"
                    error={errorMessage["image_url"]}
                    FormHelperText={errorMessage["image_url"]}
                  />
                </Button>
                <FormHelperText error>{errorMessage["image_url"]}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                px: 3,
                columnGap: 5,
              }}
            >
              {/* title Eng */}
              <FormControl sx={{ my: 2, maxWidth: 800 }} variant="outlined">
                <TextField
                  id="title_en"
                  label="Title Eng"
                  {...register("title_en")}
                  error={!!errors.title_en}
                  helperText={errors.title_en?.message}
                />
              </FormControl>
              {/* title MM */}
              <FormControl sx={{ my: 2 }} variant="outlined">
                <TextField
                  id="title_mm"
                  label="Title MM"
                  {...register("title_mm")}
                  error={!!errors.title_mm}
                  helperText={errors.title_mm?.message}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                px: 3,
              }}
            >
              {/* paragraph Eng */}
              <Box>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph Eng
                </InputLabel>
                <Controller
                name="paragraphEng"
                control={control}
                render={() => (
                <RichTextEditor
                value={parEng} // Controlled value
                onChange={handleParagraphEng}
                />
              )}
/>
          {errors.paragraph_en && (
            <FormHelperText error>{errors.paragraph_en.message}</FormHelperText>
          )}
                {/* {errorMessage.description && (
                  <FormHelperText error> {errorMessage.description}</FormHelperText>
                )} */}
                {/* <FormHelperText error>{errorMessage["paragraph_en"]}</FormHelperText> */}
              </Box>

              {/* paragraph MM*/}
              <Box sx={{ my: 2 }}>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Paragraph MM
                </InputLabel>
                <Controller
                name="paragraphMM"
                control={control}
              render={() => (
                <RichTextEditor
                  value={parMM} // Controlled value
                  onChange={handleParagraphMM}
                />
              )}
/>
          {errors.paragraph_mm && (
            <FormHelperText error>{errors.paragraph_mm.message}</FormHelperText>
          )}
              </Box>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
            type="submit"
              variant="contained"
              loading={loading}
              // onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
