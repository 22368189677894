import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getAllUsers = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}api/company?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getOneUser = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}api/company/${id}`, config);
  return response;
};

const searchUser = async (token, username) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const res = await axios.get(
    `${BACKEND_URL}api/company/${username}`,
    config
  );
  return res;
};

const deleteUser = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const res = await axios.delete(`${BACKEND_URL}api/company/${id}`, config);
  return res;
};


export default {
  getAllUsers,
  getOneUser,
  searchUser,
  deleteUser,
};
